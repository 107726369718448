import { render, staticRenderFns } from "./RegistriesItem.vue?vue&type=template&id=0950e189&scoped=true&"
import script from "./RegistriesItem.vue?vue&type=script&lang=js&"
export * from "./RegistriesItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0950e189",
  null
  
)

export default component.exports